var render = function () {
  var _vm$paket;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-card', {
    staticClass: "mb-2"
  }, [_c('header', [_c('h4', {
    staticClass: "text-center"
  }, [_c('strong', [_vm._v(" Laporan Tryout " + _vm._s((_vm$paket = _vm.paket) === null || _vm$paket === void 0 ? void 0 : _vm$paket.name) + " ")])])]), _c('hr'), _c('section', [_c('table', {
    staticClass: "w-100"
  }, [_c('tr', [_c('td', [_vm._v("Nama")]), _c('th', [_vm._v(":")]), _c('th', [_vm.siswa ? _c('span', [_vm._v(_vm._s(_vm.siswa.name))]) : _c('i', {
    staticClass: "text-danger"
  }, [_vm._v(" Nama siswa tidak ditemukan ")])])]), _c('tr', [_c('td', [_vm._v("Asal Sekolah")]), _c('th', [_vm._v(":")]), _c('th', [_vm.siswa && _vm.siswa.detail && _vm.siswa.detail.school_origin_info ? _c('span', [_vm._v(" " + _vm._s(_vm.siswa.detail.school_origin_info.nama_sekolah) + " ")]) : _c('i', {
    staticClass: "text-danger"
  }, [_vm._v(" Sekolah siswa tidak ditemukan ")])])]), _vm.jadwal ? _c('tr', [_c('td', [_vm._v("Info Jadwal")]), _c('th', [_vm._v(":")]), _c('th', [_vm._v(" " + _vm._s(_vm.jadwal.name) + " -> " + _vm._s(_vm.jadwal.start_time) + " s/d " + _vm._s(_vm.jadwal.end_time) + " ")])]) : _vm._e()])])]), _vm.result && _vm.result.nilai_sub_categories ? _c('b-card-actions', {
    ref: "nilai-tes-card",
    attrs: {
      "title": "Data Benar & Salah Siswa",
      "action-collapse": ""
    }
  }, [_c('header', [_c('b-table-simple', {
    attrs: {
      "responsive": "",
      "bordered": "",
      "striped": "",
      "hover": ""
    }
  }, [_c('b-tr', [_c('b-th'), _c('b-th', [_vm._v("Total Soal")]), _c('b-th', [_vm._v("Jumlah Benar")]), _c('b-th', [_vm._v("Jumlah Salah")]), _c('b-th', [_vm._v("Jumlah Kosong")]), _c('b-th', [_vm._v("Nilai")]), _c('b-th', [_vm._v("Total %")])], 1), _vm._l(_vm.result.nilai_sub_categories, function (subcat, i) {
    return _c('b-tr', {
      key: i
    }, [_c('b-th', [_vm._v(" " + _vm._s(subcat.name) + " ")]), _c('b-td', [_vm._v(" " + _vm._s(subcat.total_soal) + " ")]), _c('b-td', [_vm._v(" " + _vm._s(subcat.jumlah_benar) + " ")]), _c('b-td', [_vm._v(" " + _vm._s(subcat.jumlah_salah) + " ")]), _c('b-td', [_vm._v(" " + _vm._s(subcat.jumlah_kosong) + " ")]), _c('b-td', [_vm._v(" " + _vm._s(subcat !== null && subcat !== void 0 && subcat.nilai ? parseFloat(subcat === null || subcat === void 0 ? void 0 : subcat.nilai).toFixed(2) : 0) + " ")]), i == 0 ? _c('b-td', {
      attrs: {
        "rowspan": "2"
      }
    }, [_vm._v(_vm._s(_vm.getAverage(_vm.result.nilai_sub_categories)))]) : _vm._e()], 1);
  })], 2)], 1)]) : _vm._e(), _c('b-card-actions', {
    ref: "hasil-card",
    attrs: {
      "title": "Rasionalisasi Nilai UTBK",
      "action-collapse": ""
    }
  }, [_c('b-table-simple', {
    attrs: {
      "responsive": ""
    }
  }, [_c('b-tr', [_c('b-th', {
    staticClass: "text-center",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("SKOR TO")]), _c('b-th', {
    staticClass: "text-center",
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("PTN DAN JURUSAN PILIHAN")]), _c('b-th', {
    staticClass: "text-center",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("PG KELULUSAN")]), _c('b-th', {
    staticClass: "text-center",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("PELUANG KELULUSAN")])], 1), _c('b-tr', [_c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("PTN")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("PRODI")])], 1), _vm._l(_vm.result.selected_majors, function (item, i) {
    var _item$school;
    return _c('b-tr', {
      key: i
    }, [_c('b-td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(item.nilai_akhir))]), _c('b-td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s((_item$school = item.school) === null || _item$school === void 0 ? void 0 : _item$school.name))]), _c('b-td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(item.name))]), _c('b-td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(item.passing_grade_percentage))]), _c('b-td', {
      staticClass: "text-center"
    }, [_c('strong', {
      class: item.is_passed ? 'text-success' : 'text-danger'
    }, [_vm._v(" " + _vm._s(item.is_passed ? "LULUS" : "TIDAK LULUS") + " ")])])], 1);
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }